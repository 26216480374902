import { t } from 'ttag';

import { UNIT_INCH, UNIT_MM } from '../constants';

export const MODE_EDITOR = 'editor';
export const MODE_PREVIEW = 'preview';

export const WHITE_BACKGROUND_COLOR = '#ffffff';
export const GRAY_BACKGROUND_COLOR = '#ececec';
export const GRAY_BORDER_COLOR = '#bfbfbf';
export const RED_BORDER_COLOR = '#ffa39e';
export const GREEN_BORDER_COLOR = `#6DBB83`;
export const BADGE_PREVIEW_BOX_SHADOW = '4px 6px 6px 0px #00000040';

export const COLUMN_SIZE = 4;

// Normally, 1in = 25.4mm = 96px, but because of limitation due to `react-grid-layout`,
// we had to sacrifice 1.4mm every inch
// See this discussion for more details:
// https://eventmobi.slack.com/archives/C02QWMQ1MV1/p1658171194355949
export const UNIT_DIVISION = {
  [UNIT_INCH]: 24,
  [UNIT_MM]: 1,
};

export const UNIT_BLEED_LENGTH = {
  [UNIT_INCH]: 0.125,
  [UNIT_MM]: 3,
};

export const UNIT_CROP_MARK_LENGTH = {
  [UNIT_INCH]: 0.167,
  [UNIT_MM]: 4,
};

export const SIMPLE_LABEL_GAP = {
  [UNIT_INCH]: {
    // as 1mm - 1/24 inch
    fixed: 0.16, // 0.167 inch = 4mm - rounding off to render correct value
    user: 0.24, // 0.25 inch = 6mm
  },
  [UNIT_MM]: {
    fixed: 4,
    user: 6,
  },
};

export const SIMPLE_LABEL_PADDING = {
  HORIZONTAL: 3 * COLUMN_SIZE,
  VERTICAL: 2 * COLUMN_SIZE,
};

// This will give us the bleed length in columns for each unit (mm and inch).
// Currently, the bleed length for both will be same (3 columns) because (1mm => 1 column & 3mm*1 = 3) and (1 inch => 24 columns & 0.125inch*24 = 3). Look at the `UNIT_DIVISION` and `UNIT_BLEED_LENGTH` for more details.
// Having this would be useful if we ever want to have different bleed length for mm and inch.
export const BLEED_GAP = {
  [UNIT_INCH]: UNIT_BLEED_LENGTH[UNIT_INCH] * UNIT_DIVISION[UNIT_INCH],
  [UNIT_MM]: UNIT_BLEED_LENGTH[UNIT_MM] * UNIT_DIVISION[UNIT_MM],
};

export const maxSize = {
  [UNIT_INCH]: { width: 13, height: 13 },
  [UNIT_MM]: { width: 330, height: 330 },
};

export const minSize = {
  [UNIT_INCH]: { width: 2, height: 2 },
  [UNIT_MM]: { width: 51, height: 51 },
};

// following types need to be same as Flux
export const TYPE_IMAGE = 'image';
export const TYPE_RICH_TEXT = 'rich_text';
export const TYPE_SHAPE = 'shape';
export const TYPE_PERSONAL_CODE = 'qr_code';
export const TYPE_PAGE_BACKGROUND = 'background';

export const SHAPE_TRIANGLE = 'triangle';
export const SHAPE_RECT = 'rect';
export const SHAPE_CIRCLE = 'circle';

export const TYPES = () => ({
  [TYPE_RICH_TEXT]: t`Text Block`,
  [TYPE_IMAGE]: t`Image`,
  [TYPE_PAGE_BACKGROUND]: t`Background`,
  [TYPE_SHAPE]: t`Shape`,
  [TYPE_PERSONAL_CODE]: t`QR Code`,
});

export const TYPES_FOR_SIMPLE_LABEL = () => ({
  [TYPE_RICH_TEXT]: t`Text Block`,
  [TYPE_SHAPE]: t`Shape`,
  [TYPE_PERSONAL_CODE]: t`QR Code`,
});

export const TYPE_DESCRIPTION = () => ({
  [TYPE_PERSONAL_CODE]: t`Used for Check In`,
});

export const TYPE_MAX_COUNT = {
  [TYPE_RICH_TEXT]: 100,
  [TYPE_IMAGE]: 100,
  [TYPE_SHAPE]: 100,
  [TYPE_PERSONAL_CODE]: 1,
};

export const MARGIN_X = 0;
export const MARGIN_Y = 0;
export const MARGIN = [MARGIN_X, MARGIN_Y];

export const PADDING_X = 0;
export const PADDING_Y = 0;
export const PADDING = [PADDING_X, PADDING_Y];

export const PREFERRED_DIMENSIONS = {
  [TYPE_RICH_TEXT]: { [UNIT_INCH]: { h: 2, w: 2 }, [UNIT_MM]: { h: 48, w: 48 } },
  [TYPE_IMAGE]: { [UNIT_INCH]: { h: 2, w: 2 }, [UNIT_MM]: { h: 48, w: 48 } },
  [TYPE_SHAPE]: { [UNIT_INCH]: { h: 2, w: 2 }, [UNIT_MM]: { h: 48, w: 48 } },
  [TYPE_PERSONAL_CODE]: { [UNIT_INCH]: { h: 1, w: 1 }, [UNIT_MM]: { h: 24, w: 24 } },
};

export const MINIMUM_DIMENSIONS = {
  // the actual value calculated is 76.8px but react-grid-layout doesn't support decimal values
  // so it is rounding it up to 77px as the minimum width and height. This should not pose any issues
  // but putting it here in case it does.
  [TYPE_PERSONAL_CODE]: { [UNIT_INCH]: { h: 0.8, w: 0.8 }, [UNIT_MM]: { h: 19.2, w: 19.2 } },
};

const roundFloat = v => parseFloat(v.toFixed(1));

// normalizeUnit converts the value to mm
// it is used to bring all values to a common unit
export const normalizeUnit = (v, unit) =>
  unit === UNIT_INCH ? roundFloat(v * UNIT_DIVISION[UNIT_INCH]) : v;
