/* global APPCUES_ACCOUNT_ID */
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useParams } from 'react-router-dom';
import styled from 'styled-components';

import HTTP500 from 'common/components/ErrorRoutes/HTTP500';
import ErrorRoutes from 'common/components/ErrorRoutes/Routes';
import Loading from 'common/components/Loading';
import TranslationProvider from 'common/components/Translation/Provider';
import useSearchParams from 'common/hooks/useSearchParams';
import { setPortal } from 'common/state/system';
import Portal from 'common/types/Portal';

import { Provider as AppcuesProvider } from '@components/Appcues';
import CookieConsent from '@components/CookieConsent';
import { Provider as UploadingDrawerProvider } from '@components/UploadingDrawer';
import ActivityFeedModeration from '@domains/ActivityFeed/Moderation';
import authRoutes, { PrivateRoute, StaffRoute } from '@domains/Auth';
import AcceptInvite from '@domains/Auth/AcceptInvite';
import ActivateAccount from '@domains/Auth/ActivateAccount';
import Capture from '@domains/Capture';
import CapturePDF from '@domains/Capture/PDF';
import Event from '@domains/Event';
import Leaderboard from '@domains/Leaderboard';
import LiveResultsPage from '@domains/LiveResults';
import LiveResultsModeration from '@domains/LiveResults/Moderation';
import Organization from '@domains/Organization';
import Settings from '@domains/Organization/Organizer/Settings';
import store from '@state/store';

import 'chart.js/auto';
import './setup-sentry';

import Layout from './Layout';
import GlobalStyles from './overrides';
import RQDevTools from './RQDevTools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Legacy = lazy(() => import(/* webpackChunkName: "legacy" */ '@domains/Legacy'));

const Wrap = styled.div`
  height: 100vh;
  font-family: 'Open Sans', sans-serif;

  & > p {
    margin: 0;
    padding: 0;
  }
`;

const AcceptInviteRedirect = () => {
  const query = useSearchParams();
  const organizationId = query?.get('organization_id');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const effect = async () => {
      setIsLoading(false);
    };

    effect();
  }, [organizationId]);

  if (isLoading) {
    return <Loading />;
  }

  return <AcceptInvite />;
};

const OauthRedirect = () => {
  // Auth from eventbrite doesn't come with a type specified
  const { type = 'eventbrite' } = useParams();
  const query = useSearchParams();
  const code = query?.get('code');
  const error = query?.get('error');

  return <Redirect to={`/oauth-redirect/${type}/${code}${error ? `/${error}` : ''}`} />;
};

const basename = '/';

const Routes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPortal(Portal.Experience));
  }, [dispatch]);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>
        <PrivateRoute path="/settings/:tab" exact>
          <Layout>
            <Settings />
          </Layout>
        </PrivateRoute>
        <Route path="/event/:eventId/activity-feed/moderation">
          <ActivityFeedModeration />
        </Route>
        <PrivateRoute path="/organization/:organizationId/event/:eventId">
          <Layout>
            <Event />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path={['/organization/:organizationId', '/organization']}>
          <Layout>
            <Organization />
          </Layout>
        </PrivateRoute>
        <Route path="/event/:eventId/:shortcode/gamification-leaderboard/:hashVal">
          <Leaderboard />
        </Route>
        <Route path="/event/:eventId/capture/:resourceType/:resourceId" exact>
          <Capture />
        </Route>
        <Route path="/event/:eventId/pdf-capture">
          <CapturePDF />
        </Route>
        <Route
          path={[
            '/event/:eventId/question/moderate-live-results/:hash/:questionType/:sessionId',
            '/event/:eventId/question/:questionId/moderate-live-results/:hash/:questionType/:sessionId',
            '/event/:eventId/question/:questionId/moderate-live-results/:hash/:questionType',
          ]}
        >
          <LiveResultsModeration />
        </Route>
        <Route
          path={[
            '/event/:eventId/question/:questionId/live-results/:hash/:questionType/:sessionId',
            '/event/:eventId/question/:questionId/live-results/:hash/:questionType',
            '/event/:eventId/question/live-results/:hash/:questionType/:sessionId',
          ]}
        >
          <LiveResultsPage />
        </Route>
        <StaffRoute path="/admin">
          <Layout>
            <Legacy />
          </Layout>
        </StaffRoute>
        <Route path="/accept-invite" exact>
          <AcceptInviteRedirect />
        </Route>
        <Route path={['/oauth-redirect/:type', '/oauth-redirect']} exact>
          <OauthRedirect />
        </Route>
        <Route
          path={[
            '/self-edit',
            '/accept-invite/:token/:email/:organizationId',
            '/oauth-redirect/:type/:code/:error?',
            '/event/:eventId/question',
          ]}
        >
          <Legacy />
        </Route>
        <Route path="/activate" exact>
          <ActivateAccount />
        </Route>
        {authRoutes}
        {ErrorRoutes}
      </Switch>
    </Suspense>
  );
};

/**
 * Render just the necessary parts of the app for the capture page and other unframed pages.
 * If you are changing this, please ensure that we're not adding extra providers to the PDF/image
 * capture pages for badge printing, as some of the providers will cause a blank page to be
 * rendered and will break badge printing.
 */
const SlimApp = ({ children }) => (
  <Wrap>
    <GlobalStyles />
    <TranslationProvider suffix="-react">
      <ErrorBoundary fallback={<HTTP500 />}>
        <QueryClientProvider client={queryClient}>
          <RQDevTools />
          <Provider store={store}>{children}</Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </TranslationProvider>
  </Wrap>
);

SlimApp.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Render all of the providers that are used for a typical page.
 */
const App = () => (
  <SlimApp>
    <AppcuesProvider>
      <DndProvider backend={HTML5Backend}>
        <UploadingDrawerProvider>
          <Helmet defer={false}>
            <base href={basename} />
            <script type="text/javascript">
              {`window.AppcuesSettings = { enableURLDetection: true };`}
            </script>
            {APPCUES_ACCOUNT_ID && (
              <script async src={`//fast.appcues.com/${APPCUES_ACCOUNT_ID}.js`} />
            )}
          </Helmet>
          <Routes />
          <CookieConsent />
        </UploadingDrawerProvider>
      </DndProvider>
    </AppcuesProvider>
  </SlimApp>
);

/**
 * Use the route to check the current path and render either a slim or full set of app providers.
 */
const FullOrSlimApp = () => (
  <Router basename={basename}>
    <Switch>
      <Route path={['/event/:eventId/capture', '/event/:eventId/pdf-capture']}>
        <SlimApp>
          <Routes />
        </SlimApp>
      </Route>
      <Route>
        <App />
      </Route>
    </Switch>
  </Router>
);

export default hot(FullOrSlimApp);
