import { t } from 'ttag';

export const ERROR_MESSAGE_BY_CODE = () => ({
  badge_entity_name_already_exists: t`A badge with this name already exists. Please enter a different name.`,
  badge_entity_not_found: t`Badge not found.`,
});

export const UNIT_INCH = 'inch';
export const UNIT_MM = 'mm';
export const UNIT_LABEL = {
  [UNIT_INCH]: 'in',
  [UNIT_MM]: 'mm',
};

export const BadgeTypes = {
  SIMPLE_LABEL: 'simple_label',
  PRE_PRINT: 'pre_print',
  PRINT_BY_YOURSELF: 'print_by_yourself',
};

export const BADGE_LEGEND_TYPE = {
  None: 'none',
  Bleed: 'bleed',
  SimpleLabel: 'simple_label',
};

export const ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

// For standard sizes, we only use sizes in inches.
export const StandardBadgeSizes = {
  [BadgeTypes.SIMPLE_LABEL]: { sizes: [{ width: 83, height: 57 }], unit: UNIT_MM },
  [BadgeTypes.PRE_PRINT]: {
    sizes: [
      { width: 4, height: 3 },
      { width: 4, height: 6 },
    ],
    unit: UNIT_INCH,
  },
  [BadgeTypes.PRINT_BY_YOURSELF]: {
    sizes: [
      { width: 4, height: 3 },
      { width: 4, height: 6 },
    ],
    unit: UNIT_INCH,
  },
};

export const APPLY_TO_ALL = 'all_people';
export const APPLY_TO_PEOPLE = 'specific_people';
export const APPLY_TO_GROUPS = 'people_groups';
export const APPLY_TO_ROLES = 'session_roles';

// For a given unit, if any rule in the array is matched, it should be considered as oversize badge
// When a badge is "oversized", the Page Size in "Download" modal, is set to Badge Size by default
const OVERSIZE_BADGE_RULES = {
  [UNIT_INCH]: [{ width: 8 }, { height: 10 }],
  [UNIT_MM]: [{ width: 203 }, { height: 254 }],
};

export const validateOversizeBadgeRules = (unit, value) => {
  if (!value) return false;

  const matchedRule = OVERSIZE_BADGE_RULES[unit].find(
    rule =>
      (rule.width ? value.width > rule.width : true) &&
      (rule.height ? value.height > rule.height : true)
  );

  return !!matchedRule;
};

export const sizeMap = {
  [UNIT_INCH]: [
    { width: 4, height: 3 },
    { width: 4, height: 6 },
  ],
  [UNIT_MM]: [
    { width: 102, height: 76 },
    { width: 102, height: 152 },
  ],
};

export const isPredefinedSize = size => {
  const dimensions = sizeMap[size.unitType];
  const isDimensionCorrect = dimensions.some(
    ({ width, height }) => width === Number(size.width) && height === Number(size.height)
  );

  return isDimensionCorrect;
};

export const getStandardSizes = () => ({
  small: {
    width: 4,
    height: 3,
    unitType: UNIT_INCH,
  },
  large: {
    width: 4,
    height: 6,
    unitType: UNIT_INCH,
  },
  [BadgeTypes.SIMPLE_LABEL]: {
    width: 83,
    height: 57,
    unitType: UNIT_MM,
  },
});

export const isStandardSizeBadge = badgeSize =>
  Object.values(getStandardSizes()).some(
    ({ width, height }) => badgeSize.width === width && badgeSize.height === height
  );
